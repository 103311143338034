
import { Authenticator } from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Image, Heading, Text, View, ThemeProvider, useTheme, Button, Card } from "@aws-amplify/ui-react";
import iravadlogo from "../iravad-logo-full.png";
import { MarketingPricingHome, FeaturesHome, CTASectionHome, MarketingFooterHome } from "../ui-components";
import '../App.css';



export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);



  // Function to add welcome message with authenticator
  const components = {
    // Header() {
    //   const { tokens } = useTheme();
    //   return (
    //     <View textAlign="center">
    //       <Image
    //         alt="Iravad logo"
    //         src={iravadlogo}
    //         width="120px"
    //         height="120px"
    //       />
    //       <Heading level={1} style={{ fontSize: '32px' }}>
    //         Welcome to Iravad OCR!
    //       </Heading>
    //       </View>
    //   );
    // },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" >
          <Text style={{ fontSize: '14px', color: 'GrayText', paddingTop: '10px'}}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
  };

  const fontStyle = { fontFamily: "Sans-serif" };
   

  return (
    <div>

    <div style={{ textAlign: "left", paddingTop: '150px' }}>
    <Authenticator components={components} >
    </Authenticator>
    </div>

    <Card style={{ display: 'flex', flex: '1', overflowY: 'auto'}}>

    <div style={{ display: 'flex',  flex: '1', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap'}}>

    <MarketingPricingHome overrides={{
    "Pricing": fontStyle,
    "Choose your plan": fontStyle,
    "Basic plan": fontStyle,
    "$40/month": fontStyle,
    "Upto 500 scans/month": fontStyle,
    "Receipts & Invoices only50231328": fontStyle,
    "CSV, json export formats50231332": fontStyle,
    "Developer friendly API50231336": fontStyle,
    "Pro plan": fontStyle,
    "$250/month": fontStyle,
    "Upto 5,000 scans/month": fontStyle,
    "Receipts & Invoices only50231370": fontStyle,
    "CSV, json export formats50231374": fontStyle,
    "Developer friendly API50231378": fontStyle,
    "Line item extraction50231382": fontStyle,
    "Multiple language support50231386": fontStyle,
    "Enterprise plan": fontStyle,
    "$400/month": fontStyle,
    "Upto 10,000 scans/month": fontStyle,
    "Receipts & Invoices only50231289": fontStyle,
    "CSV, json export formats50231293": fontStyle,
    "Developer friendly API50231297": fontStyle,
    "Line item extraction50231301": fontStyle,
    "Multiple language support50231305": fontStyle,
    "Customisation support": fontStyle
    }} />


    <FeaturesHome overrides={{ 
    "Feature A49821196": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821197": fontStyle,
    "Feature B49821201": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821202"
    : fontStyle,
    "Feature A49821223": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821224"
    : fontStyle,
    "Feature B49821228": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821229"
    : fontStyle,
    "Feature A49821237": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821238": fontStyle,
    "Feature B49821242": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821243": fontStyle
  }} />

<MarketingFooterHome />

    {/* <CTASectionHome overrides={{ 
    "Newsletter": fontStyle, 
    "Stay in touch and never miss an update": fontStyle,
    "Keep up to date on the latest happenings at Iravad": fontStyle,
  }} />
    
    <MarketingFooterHome overrides={{ "Sign up for our newsletter" : {fontFamily : "Sans-serif"  },
    "No spam. We promise.": fontStyle,
    "Products" : fontStyle,
    "Libraries": fontStyle,
    "CLI": fontStyle,
    "Studio": fontStyle,
    "Hosting": fontStyle,
    "Resources": fontStyle,
    "Docs": fontStyle,
    "Learn": fontStyle,
    "Examples": fontStyle,
    "Changelog": fontStyle,
    "Company": fontStyle,
    "About us": fontStyle,
    "Contact": fontStyle,
    "Blog": fontStyle,
    "\u00A9 2024 Iravad All rights reserved.": fontStyle
    }} /> */}

    </div>

    </Card>
    </div>
  );
}

