/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReceipt = /* GraphQL */ `
  mutation CreateReceipt(
    $input: CreateReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    createReceipt(input: $input, condition: $condition) {
      id
      owner
      file {
        bucket
        region
        key
        __typename
      }
      filename
      NAME
      INVOICE_RECEIPT_DATE
      TOTAL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReceipt = /* GraphQL */ `
  mutation UpdateReceipt(
    $input: UpdateReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    updateReceipt(input: $input, condition: $condition) {
      id
      owner
      file {
        bucket
        region
        key
        __typename
      }
      filename
      NAME
      INVOICE_RECEIPT_DATE
      TOTAL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReceipt = /* GraphQL */ `
  mutation DeleteReceipt(
    $input: DeleteReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    deleteReceipt(input: $input, condition: $condition) {
      id
      owner
      file {
        bucket
        region
        key
        __typename
      }
      filename
      NAME
      INVOICE_RECEIPT_DATE
      TOTAL
      createdAt
      updatedAt
      __typename
    }
  }
`;
