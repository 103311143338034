
import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./pages/Layout";
import { Home } from "./pages/Home";
import { MyExpense } from "./pages/MyExpense";
import { Login } from "./pages/Login";
// import { OcrApi } from "./pages/OcrApi";
// import { MyExpenses } from "./pages/MyExpenses";
import { RequireAuth } from "./pages/RequireAuth";

import { Authenticator } from "@aws-amplify/ui-react";



function MyRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />} >
                <Route index element={<Home />} />
                <Route path="/MyExpense" element={<RequireAuth><MyExpense/></RequireAuth>} />
                {/* <Route path="/OcrApi" element={<OcrApi />} /> */}
                {/* <Route path="/MyExpenses" element={<RequireAuth><MyExpenses /></RequireAuth>} /> */}
                <Route path="/login" element={<Login />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}


function App() {
    return ( 
        <Authenticator.Provider>
            <MyRoutes />
        </Authenticator.Provider>
    );
}

export default App;
