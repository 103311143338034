/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReceipt = /* GraphQL */ `
  query GetReceipt($id: ID!) {
    getReceipt(id: $id) {
      id
      owner
      file {
        bucket
        region
        key
        __typename
      }
      filename
      NAME
      INVOICE_RECEIPT_DATE
      TOTAL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReceipts = /* GraphQL */ `
  query ListReceipts(
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        filename
        NAME
        INVOICE_RECEIPT_DATE
        TOTAL
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
