import React, { useRef } from 'react';
import emailjs from "emailjs-com";
import form from 'react';
import { Card } from '@aws-amplify/ui-react';
import { Button, Flex, Text, TextField, View } from "@aws-amplify/ui-react";


export default function ContactUs() {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_lylo81x', 'template_bj7akwg', form.current, 
       'pOBNvmTqgGiZLn2Le',
      )
      .then(
        () => {
          console.log('SUCCESS!'); 
          alert('Your message has been sent successfully. We will get back to you soon.');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      form.current.reset();
  };

  return (
    <Card style={{ backgroundColor: "#f2f6f4", width: '100%' }}> 

    <form ref={form} onSubmit={sendEmail} style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
    
    <h2 style={{ display: 'flex',justifyContent: "center", color: '#3B3B40', fontSize:'24px'}}>Contact Us</h2>


        <div style={{ display: 'flex', justifyContent: "space-evenly", flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ marginBottom: '10px', color: "#3B3B40" }}>Name</label>
              <input type="text" name="user_name" style={{ padding: '10px', fontSize: '16px', width: '400px', border: '1px solid #919098', borderRadius: '5px' }} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ marginBottom: '10px', color: "#3B3B40" }}>Email address</label>
              <input type="email" name="user_email" style={{ padding: '10px', fontSize: '16px', width: '400px' , border: '1px solid #919098', borderRadius: '5px' }} />
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: "space-evenly", flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ marginBottom: '10px', color: "#3B3B40" }}>Company</label>
              <input type="text" name="company_name" style={{ padding: '10px', fontSize: '16px', width: '400px', border: '1px solid #919098', borderRadius: '5px'  }} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ marginBottom: '10px' , color: "#3B3B40"}}>Country</label>
              <input type="text" name="country" style={{ padding: '10px', fontSize: '16px', width: '400px' , border: '1px solid #919098', borderRadius: '5px' }} />
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: "space-evenly", flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginBottom: '10px', color: "#3B3B40" }}>Application use case</label>
          <input type="text" name="use_case" style={{ padding: '10px', fontSize: '16px', width: '400px', border: '1px solid #919098', borderRadius: '5px'  }} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginBottom: '10px', color: "#3B3B40" }}>Estimated number of scans/month</label>
          <input type="number" name="scans_per_month" style={{ padding: '10px', fontSize: '16px', width: '400px', border: '1px solid #919098', borderRadius: '5px'  }} />
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
          <label style={{color: "#3B3B40" }}>Message</label>
          <textarea name="message" style={{ padding: '10px', fontSize: '16px', width: '850px', height: '120px', border: '1px solid #919098', borderRadius: '5px'  }}/>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <input type="submit" value="Send" /> */}
          <input type="submit" value="Submit" className="nav-freetrial-button" style={{width: '200px'}}/>
          </div>

        </form>
    </Card>
  );
}

    
    
