
import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ReactNode } from "react"; // Add this import statement

export function RequireAuth({ children }) { // Remove the type annotation from the children prop
    const location = useLocation();
    const { route } = useAuthenticator((context) => [context.route]);
    
    if (route !== "authenticated") {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
}
