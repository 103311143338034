/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createReceipt } from "../graphql/mutations";
const client = generateClient();
export default function ReceiptCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    owner: "",
    filename: "",
    NAME: "",
    INVOICE_RECEIPT_DATE: "",
    TOTAL: "",
  };
  const [owner, setOwner] = React.useState(initialValues.owner);
  const [filename, setFilename] = React.useState(initialValues.filename);
  const [NAME, setNAME] = React.useState(initialValues.NAME);
  const [INVOICE_RECEIPT_DATE, setINVOICE_RECEIPT_DATE] = React.useState(
    initialValues.INVOICE_RECEIPT_DATE
  );
  const [TOTAL, setTOTAL] = React.useState(initialValues.TOTAL);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setOwner(initialValues.owner);
    setFilename(initialValues.filename);
    setNAME(initialValues.NAME);
    setINVOICE_RECEIPT_DATE(initialValues.INVOICE_RECEIPT_DATE);
    setTOTAL(initialValues.TOTAL);
    setErrors({});
  };
  const validations = {
    owner: [],
    filename: [],
    NAME: [],
    INVOICE_RECEIPT_DATE: [],
    TOTAL: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          owner,
          filename,
          NAME,
          INVOICE_RECEIPT_DATE,
          TOTAL,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createReceipt.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ReceiptCreateForm")}
      {...rest}
    >
      <TextField
        label="Owner"
        isRequired={false}
        isReadOnly={false}
        value={owner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              owner: value,
              filename,
              NAME,
              INVOICE_RECEIPT_DATE,
              TOTAL,
            };
            const result = onChange(modelFields);
            value = result?.owner ?? value;
          }
          if (errors.owner?.hasError) {
            runValidationTasks("owner", value);
          }
          setOwner(value);
        }}
        onBlur={() => runValidationTasks("owner", owner)}
        errorMessage={errors.owner?.errorMessage}
        hasError={errors.owner?.hasError}
        {...getOverrideProps(overrides, "owner")}
      ></TextField>
      <TextField
        label="Filename"
        isRequired={false}
        isReadOnly={false}
        value={filename}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              owner,
              filename: value,
              NAME,
              INVOICE_RECEIPT_DATE,
              TOTAL,
            };
            const result = onChange(modelFields);
            value = result?.filename ?? value;
          }
          if (errors.filename?.hasError) {
            runValidationTasks("filename", value);
          }
          setFilename(value);
        }}
        onBlur={() => runValidationTasks("filename", filename)}
        errorMessage={errors.filename?.errorMessage}
        hasError={errors.filename?.hasError}
        {...getOverrideProps(overrides, "filename")}
      ></TextField>
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={NAME}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              owner,
              filename,
              NAME: value,
              INVOICE_RECEIPT_DATE,
              TOTAL,
            };
            const result = onChange(modelFields);
            value = result?.NAME ?? value;
          }
          if (errors.NAME?.hasError) {
            runValidationTasks("NAME", value);
          }
          setNAME(value);
        }}
        onBlur={() => runValidationTasks("NAME", NAME)}
        errorMessage={errors.NAME?.errorMessage}
        hasError={errors.NAME?.hasError}
        {...getOverrideProps(overrides, "NAME")}
      ></TextField>
      <TextField
        label="Invoice receipt date"
        isRequired={false}
        isReadOnly={false}
        value={INVOICE_RECEIPT_DATE}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              owner,
              filename,
              NAME,
              INVOICE_RECEIPT_DATE: value,
              TOTAL,
            };
            const result = onChange(modelFields);
            value = result?.INVOICE_RECEIPT_DATE ?? value;
          }
          if (errors.INVOICE_RECEIPT_DATE?.hasError) {
            runValidationTasks("INVOICE_RECEIPT_DATE", value);
          }
          setINVOICE_RECEIPT_DATE(value);
        }}
        onBlur={() =>
          runValidationTasks("INVOICE_RECEIPT_DATE", INVOICE_RECEIPT_DATE)
        }
        errorMessage={errors.INVOICE_RECEIPT_DATE?.errorMessage}
        hasError={errors.INVOICE_RECEIPT_DATE?.hasError}
        {...getOverrideProps(overrides, "INVOICE_RECEIPT_DATE")}
      ></TextField>
      <TextField
        label="Total"
        isRequired={false}
        isReadOnly={false}
        value={TOTAL}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              owner,
              filename,
              NAME,
              INVOICE_RECEIPT_DATE,
              TOTAL: value,
            };
            const result = onChange(modelFields);
            value = result?.TOTAL ?? value;
          }
          if (errors.TOTAL?.hasError) {
            runValidationTasks("TOTAL", value);
          }
          setTOTAL(value);
        }}
        onBlur={() => runValidationTasks("TOTAL", TOTAL)}
        errorMessage={errors.TOTAL?.errorMessage}
        hasError={errors.TOTAL?.hasError}
        {...getOverrideProps(overrides, "TOTAL")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
