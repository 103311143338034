import { Card , Divider, Image, Flex, View } from "@aws-amplify/ui-react";
import iravadlogo from "../iravad-logo-full.png";
import HomePageImage from "../HomePageImage.jpg";
import receiptexample from "../receipt-example.png";
import livescanjsonresult from "../livescan-jsonresult.png";
import livescanjson from "../livescan-json.png";
import livescanreceiptresult from "../livescan-receipt-result.png";
import livescancsvresult from "../livescan-csv-result.png";
import { MarketingPricingHome2, FeaturesHome, CTASectionHome, MarketingFooterHome } from "../ui-components";
import ContactUs from "../ui-components/ContactUs";
import React, { useState } from 'react';
import { on } from "events";
import Head from 'next/head';



const fontStyle = { fontFamily: "Sans-serif" };




export function Home() {
  return (
    <div> 
    <Card style={{ display: 'flex', flex: '1', overflowY: 'auto', overflowX: "hidden"}}>

    <main>
    <div style={{ display: 'flex',  flex: '1',  flexDirection: 'column'}}>

      <Card style={{  display: 'flex', flex: '1', justifyContent: "center", alignItems: "center", height: '100vh',  backgroundColor: '#f2f6f4', paddingTop: "90px" }}>
      <Flex direction={{ base: 'column', medium: 'column', large: 'column', xl: 'row' }} >

        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
        <View fontSize={{ base: '14px', large: '22px' }}>
        <h1 style={{ color:'#3B3B40', marginBottom: '5px' }}>Convert Documents to Data</h1>
        <h1 style={{ color:'#3B3B40', marginTop: '0' }}>with our AI-powered technology!</h1>
        </View>
        </div>
        <div style={{ display: 'flex', flex: '1.5',  justifyContent: "center", alignItems: 'center'}}>
        <View width={{ base: '360px', large: "760px" }} height={{ base: '360px', large: "760px" }} >
        <Image src={HomePageImage} alt="LoginPageImage" borderRadius="5px" />
        </View>
        </div>
        </Flex>

      </Card>
      </div>


    <div style={{ display: 'flex',  flex: '1', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>

      <div style={{ display: 'flex',  flex: '1', justifyContent: 'center', alignItems: 'center'}}>
      <View fontSize={{ base: '16px', medium: '20px', large: '22px', xl: '24px' }} width={{ base: '300px', medium: '800px', large: '1600px' }} >
      <h2 style={{ color: '#3B3B40'}}>Extract text from images and documents</h2>
      <Divider /><br />
      <p >Our OCR API is a powerful tool that can be used to extract text from images and documents. 
          It is designed to be easy to use and can be integrated into your existing applications with minimal effort. 
          Our API supports a wide range of file formats and languages, making it ideal for a variety of use cases. 
          Whether you need to extract text from scanned documents, images or PDFs, our OCR API can help you get the job done quickly and accurately.</p><br />
      <Divider />
      <h2 style={{ color: '#3B3B40' }}> Our OCR API offers two main services:<br /></h2>
      <p >
      1. Click <span style={{ color: "#fb3a46", fontWeight:"bold" }}>Live Scan </span> in menubar - Free trials + Extract data in real-time and <span style={{ color: "#fb3a46", fontWeight:"bold" }}>download CSV</span> file on the go. <br /><br />  
      2. Contact Us to get <span style={{ color: "#fb3a46", fontWeight:"bold" }}>API</span> - Extract data using our API and export the results in <span style={{ color: "#fb3a46", fontWeight:"bold" }}>JSON</span> format. <br /><br /></p>
    </View>
    </div>
    </div>


    <Card style={{ display: 'flex', flex: '1', justifyContent: "space-around", alignItems: "center", backgroundColor: '#f2f6f4'}}>
    <View width={{ base: '300px', medium: '800px', large: '1600px' }} >
    <Flex direction={{ base: 'column', large: 'row' }} >
    {/* <div style={{ alignItems: "center" }}>
    <h2 style={{ color: '#fb3a46', fontSize:'24px'}}>Receipt</h2>
    <Image src={receiptexample} alt="ReceiptExample" style={{ height: "auto" }}/>
    </div> */}

    <div style={{ alignItems: "center"}}>
    <h2 style={{ color: '#fb3a46', fontSize:'24px'}}>Live Result</h2>
    <Image src={livescanreceiptresult} alt="LiveScanReceiptResult" style={{ height: "auto" }}/>
    </div>

    {/* <div style={{ alignItems: "center" }}>
    <h2 style={{ color: '#fb3a46', fontSize:'24px'}}>JSON</h2>
    <Image src={livescanjson} alt="LiveScanJson" style={{ height: "auto" }}/>
    </div> */}

    <div style={{ alignItems: "center" }}>
    <h2 style={{ color: '#fb3a46', fontSize:'24px'}}>Download-able CSV</h2>
    <Image src={livescancsvresult} alt="LiveScanCsv" style={{ height: "auto" }}/>
    </div>
    </Flex>
    </View>
    </Card>

    </main>
    </Card> 


    <Card style={{ display: 'flex', flex: '1', overflowY: 'auto'}}>
    <div style={{ display: 'flex',  flex: '1', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap'}}>
    <MarketingPricingHome2 overrides={{
    "Pricing": fontStyle,
    "Choose your plan": fontStyle,
    "Basic plan": fontStyle,
    "$40/month": fontStyle,
    "Upto 500 scans/month": fontStyle,
    "Receipts & Invoices only50231328": fontStyle,
    "CSV, json export formats50231332": fontStyle,
    "Developer friendly API50231336": fontStyle,
    "Pro plan": fontStyle,
    "$250/month": fontStyle,
    "Upto 5,000 scans/month": fontStyle,
    "Receipts & Invoices only50231370": fontStyle,
    "CSV, json export formats50231374": fontStyle,
    "Developer friendly API50231378": fontStyle,
    "Line item extraction50231382": fontStyle,
    "Multiple language support50231386": fontStyle,
    "Enterprise plan": fontStyle,
    "$400/month": fontStyle,
    "Upto 10,000 scans/month": fontStyle,
    "Receipts & Invoices only50231289": fontStyle,
    "CSV, json export formats50231293": fontStyle,
    "Developer friendly API50231297": fontStyle,
    "Line item extraction50231301": fontStyle,
    "Multiple language support50231305": fontStyle,
    "Customisation support *": fontStyle,
    //scroll down button to the contact us section
    Button50231349: { onClick: () => { window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }) } },
    Button50231391: { onClick: () => { window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }) } },
    Button50231310: { onClick: () => { window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }) } },
    }} />



    <FeaturesHome overrides={{ 
    "Feature A49821196": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821197": fontStyle,
    "Feature B49821201": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821202"
    : fontStyle,
    "Feature A49821223": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821224"
    : fontStyle,
    "Feature B49821228": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821229"
    : fontStyle,
    "Feature A49821237": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821238": fontStyle,
    "Feature B49821242": fontStyle,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.49821243": fontStyle
  }} />

<ContactUs />
<MarketingFooterHome />


    {/* <CTASectionHome overrides={{ 
    "Newsletter": fontStyle, 
    "Stay in touch and never miss an update": fontStyle,
    "Keep up to date on the latest happenings at Iravad": fontStyle,
  }} />
    
    <MarketingFooterHome overrides={{ "Sign up for our newsletter" : {fontFamily : "Sans-serif"  },
    "No spam. We promise.": fontStyle,
    "Products" : fontStyle,
    "Libraries": fontStyle,
    "CLI": fontStyle,
    "Studio": fontStyle,
    "Hosting": fontStyle,
    "Resources": fontStyle,
    "Docs": fontStyle,
    "Learn": fontStyle,
    "Examples": fontStyle,
    "Changelog": fontStyle,
    "Company": fontStyle,
    "About us": fontStyle,
    "Contact": fontStyle,
    "Blog": fontStyle,
    "\u00A9 2024 Iravad All rights reserved.": fontStyle
    }} /> */}

    </div>
    </Card>

    </div>

);
}
