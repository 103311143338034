import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Authenticator } from "@aws-amplify/ui-react";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";
import awsExports from "./aws-exports";
import './App.css';


Amplify.configure(awsExports);

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container as HTMLElement);
root.render(<App />);

reportWebVitals();


// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//       {/* <ThemeProvider theme={studioTheme}>
//         <Authenticator.Provider> */}
//           <App />
//         {/* </Authenticator.Provider>
//       </ThemeProvider> */}
//   </React.StrictMode>
// );

