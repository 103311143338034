import { Divider, useAuthenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from 'uuid';
import React, { MouseEventHandler } from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import awsconfig from '../aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { getReceipt } from '../graphql/queries'; 
import { createReceipt, deleteReceipt } from '../graphql/mutations';
import { uploadData } from 'aws-amplify/storage';
import { generateClient } from 'aws-amplify/api';
import { useState, useEffect, useRef, useCallback } from 'react';
import { listReceipts } from '../graphql/queries'; // Import the GraphQL query
import { fetchUserAttributes, fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Text, Card, Button, Icon, View } from '@aws-amplify/ui-react';
import { Message, Flex } from '@aws-amplify/ui-react';
import { MarketingPricingHome, FeaturesHome, CTASectionHome, MarketingFooterHome } from "../ui-components";
import { ToastContainer, toast } from 'react-toastify';

import { S3 } from 'aws-sdk';
// import { colors } from "@material-ui/core";


Amplify.configure(awsconfig);


export function MyExpense() {
  const client = generateClient();
  const { user } = useAuthenticator((context) => [context.user]);

  // Define state variables for the file upload count
  const [uploadCount, setUploadCount] = useState(0);

  // const [isFetching, setIsFetching] = useState(false);
  
  // Define state variables for the upload status
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);

  // Define state variables for the get receipt function
  const [receipt, setReceipt] = useState(null);

  // Define state variables for the receipts and the next token
  const [nextToken, setNextToken] = useState(null);

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [processedImageUrl, setProcessedImageUrl] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  // const [isZoomed, setIsZoomed] = useState(false);

  // Define a state variable for the filter
  // const [filter, setFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  // const [filteredCsvUrl, setFilteredCsvUrl] = useState('');

  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isUploadClicked, setIsUploadClicked] = useState(false);
  

  const [value, setValue] = useState('');

  // for manual record entry
  const [isManualEntryClicked, setIsManualEntryClicked] = useState(false);
  const [name, setName] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [total, setTotal] = useState('');


  //Myexpense constants
  const [receiptsTable, setReceiptsTable] = useState<any[]>([]);
  const [listIsLoading, setListIsLoading] = useState(false);

  
  // // Define a function to handle mouse enter and leave events
  // const handleMouseEnter = () => setIsZoomed(true);
  // const handleMouseLeave = () => setIsZoomed(false);


  

  // Function to print access token and id token
  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      setUserEmail(userAttributes.email ?? null);
      console.log("user:", userAttributes);
    } catch (error) {
      console.log(error);
    }
  }
    

      // Call the function to fetch user attributes
      useEffect(() => {
        handleFetchUserAttributes();
    }, []);

    


  // Assuming `getFile` is a function that retrieves a File object from an input element or another source
  const getFile = (e: React.ChangeEvent<HTMLInputElement>) => e.target.files ? e.target.files[0] : null;

  // Define an event handler for the file input change event
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    // Define a function to get the user session and group
    const session = await fetchAuthSession();
    const userEmail = session.tokens?.idToken?.payload['email'];
    const userGroup: any =  session.tokens?.idToken?.payload['cognito:groups'];
    console.log("userEmail:", userEmail);
    console.log("userGroup:", userGroup);

    // Query the number of uploads for the user using "owner" as the filter from the receipts table
    const uploadCountData: any = await client.graphql({
      query: listReceipts,
      variables: {
        filter: {
          owner: {
            eq: userEmail
          }
        },
      },
      authToken: session.tokens?.idToken?.toString() ?? ''       
    });
    console.log("uploadCountData:", uploadCountData);
    const uploadCount = uploadCountData.data.listReceipts.items.length;
    console.log("User's uploadCount:", uploadCount);


    // If the userGroup list is undefined or does not have "paid_users", set the upload limit to 2 otherwise proceed with any number of uploads
    if (!userGroup || !userGroup.includes("paid_users")) {
      console.log(userEmail + " is not a paid user")
      if (uploadCount >= 10) {
        alert ("You have reached the maximum number of trials. Please upgrade to a paid plan to process more documents.");
        return;
      }
    }

    // Increment the upload count
    setUploadCount(uploadCount + 1);


    // Get the file
    const file = getFile(e);

    // Check if file is not null before uploading
    if (file) {
      // Check the file size (50MB limit)
      const maxSize = 50 * 1024 * 1024; // 50MB in bytes
      if (file.size > maxSize) {
        alert('The selected image must be smaller than 50MB.');
        return; // Exit the function if the file is too large
      }
      // Clear the previous image
      setProcessedImageUrl(null);
      setIsFetching(true);

      setUploadStatus(null);
      setReceipt(null);
      setImageUrl(URL.createObjectURL(file));

      // Create a unique file name:
      const uuid = uuidv4();
      const extension = (file.name).split('.').pop();
      const fileNameWithoutExtension = (file.name).split('.').slice(0, -1).join('.');

    // Sanitize the filename by replacing spaces and special characters with underscores
    const sanitizedFileNameWithoutExtension = fileNameWithoutExtension.replace(/[^a-zA-Z0-9_.-]/g, '_');
    const modifiedFileName = `${sanitizedFileNameWithoutExtension}_${uuid}.${extension}`;

      // const modifiedFileName = `${fileNameWithoutExtension}_${uuid}.${extension}`;

      // const modifiedFileName = uniquefilename(file.name);


      // Define the uploadFile function
      const uploadFile = async (file: File) => {
        // Upload the Storage file:
        const result = await uploadData({ 
          key: modifiedFileName,
          // key: file.name, 
          data: file,
          options: {
            contentType: 'image/*' // contentType is optional
          }
          
        }).result;
        const image = {
          filename: modifiedFileName,
          file: {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: modifiedFileName
          }
        }
        createRecord(image);
      };

      
      // Upload the Storage file:
      const result = await uploadData({ 
        key: modifiedFileName,
        // key: file.name, 
        data: file,
        options: {
          contentType: 'image/*' // contentType is optional
        }
        
      }).result;
      const image = {
        filename: modifiedFileName,
        file: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: modifiedFileName
        }
      }
      createRecord(image);
    }
  }


  // Create a record in the database for the uploaded file with the image filename
  const createRecord = async (image: { filename: string, file: { bucket: string, region: string, key: string } }) => {
    // const delay = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds));
    // Get the current session
    const session = await fetchAuthSession();
    console.log("session:", session);

    // Create the API record:
    const newReceipt:any = client.graphql({
      query: createReceipt,
      // If createReceipt requires variables, pass them in like this:
      variables: { input: image }, 
      authToken: session.tokens?.idToken?.toString() ?? ''       
    });

    // Set the upload status
    setUploadStatus('File uploaded!');
    console.log("createRecord", newReceipt);

    setIsFetching(true); // Start fetching
    console.log("isFetching:", isFetching)

    // Call getJsonResult and wait for it to complete
    getJsonResult(image);  
  }



      
  // Define a function to add a manual entry record for expenses, with format validation
  // If Name is empty, update as N/A, If Invoice/Receipt Date is empty, update as N/A, If Total is empty, update as 0
  // Strip any leading or trailing spaces from the input fields, strip any special characters other than . and integers from the Total field,
  //Prompt the date fields as MM/DD/YYYY format for the user to enter the date
  const createManualEntryRecord = async () => {
    try {
        // Get the current session
        const session = await fetchAuthSession();
        console.log("session:", session);

        // Format validation for the input fields
        const formattedName = name.trim() === '' ? 'N/A' : name.trim();
        // Convert date format from yyyy-mm-dd to dd-mm-yyyy
        const formattedInvoiceDate = invoiceDate.split('-').reverse().join('-');
        // const formattedInvoiceDate = invoiceDate.trim() === '' ? 'N/A' : invoiceDate.trim();
        const formattedTotal = total.trim() === '' ? '0' : total.trim().replace(/[^0-9.]/g, '');

        // Create the API record with variables: "NAME", "INVOICE_RECEIPT_DATE" & "TOTAL"
        const newReceipt: any = await client.graphql({
            query: createReceipt,
            variables: { input: { NAME: formattedName, INVOICE_RECEIPT_DATE: formattedInvoiceDate, TOTAL: formattedTotal } },
            authToken: session.tokens?.idToken?.toString() ?? ''
        });

        console.log("New receipt created:", newReceipt);
        // Clear the form fields after submission
        setName('');
        setInvoiceDate('');
        setTotal('');
        
        setIsManualEntryClicked(false); // Close the modal after submission
        //call list records function to add the manualentry receipt to the list of receipts table
        listRecords();
    } catch (error) {
        console.error('Error creating manual entry record:', error);
    }
}



// Define a function to get the JSON result and CSV result from the S3 bucket 
const s3 = new S3({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? '',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? ''
  }
});

const getJsonResult = async (image: { filename: string, file: { bucket: string, region: string, key: string } }) => {
  const jsonFileName = 'processed/' + image.filename.split('.')[0] + '_complete_receipt_details' + '.json'; // replace image extension with .json
  const csvFileName = 'processed/' + image.filename.split('.')[0] + '_complete_receipt_details' + '.csv'; // replace image extension with .csv

  // get processed image filename from the original image filename
  // get original file's extension
  const file_extension = image.filename.split('.')[1];
  const processedImageFileName = 'processed/' + image.filename.split('.')[0] + '_processed' + '.' + file_extension; // replace image extension with _processed.extension

  console.log("jsonFileName:", jsonFileName);
  console.log("csvFileName:", csvFileName);
  console.log("processedImageFileName:", processedImageFileName);

  const jsonParams = {
    Bucket: image.file.bucket,
    Key: jsonFileName
  };
  console.log("jsonParams:", jsonParams);

  const csvParams = {
    Bucket: image.file.bucket,
    Key: csvFileName
  };
  console.log("csvParams:", csvParams);

  const imageParams = {
    Bucket: image.file.bucket,
    Key: processedImageFileName
  };
  console.log("imageParams:", imageParams);

  let receiptData: any;
  let csvData: any;

  setTimeout(() => {
    setIsFetching(false); // End fetching
    console.log("isFetching:", isFetching);

    s3.getObject(jsonParams, (err, data) => {
      if (err) {
        console.error("Error in getObject:", err);
        // Display a message to the user to scan the document properly again
        alert("Error in processing the document. Please scan the document properly and try again.");
        return;
      }
      receiptData = JSON.parse(data.Body?.toString() ?? '');
      console.log("receiptData:", receiptData);
      setReceipt(receiptData);
    });

    s3.getObject(csvParams, (err, data) => {
      if (err) {
        console.error("Error in getObject:", err);
        return;
      }
      csvData = data.Body?.toString();
      console.log("csvData:", csvData);
      // setDownloadUrl(csvData);
      const csvDownloadFileName = image.filename.split('.')[0] + '.csv';
      console.log("csvDownloadFileName:", csvDownloadFileName);
      csvDownloadLink(csvData, csvDownloadFileName);
    }
    );


    s3.getObject(imageParams, (err, data) => {
      if (err) {
        console.error("Error in getObject:", err);
        return;
      }
      if (data.Body) {
        const processedimageUrl = URL.createObjectURL(new Blob([data.Body as BlobPart], { type: 'image/*' }));
        console.log("processedimageUrl:", processedimageUrl);
        setProcessedImageUrl(processedimageUrl);
      } else {
        console.error("Error: data.Body is undefined");
      }
    }); 
    //call listrecords function after 6 seconds to add the receipt to the list of receipts table
  listRecords();
  }
  , 6000);
}


  // Define a function to generate download link for the csv file
 const csvDownloadLink = async (csvData: any, filename: string) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    setDownloadUrl(url);
  }
  


  // render function to display json result in list
  //format renderValue function to leave a line break after each field
  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>
              {Object.entries(item).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}:</strong> {renderValue(value)}
                  <br />
                </div>
              ))}
            </li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <ul>
          {Object.entries(value).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {renderValue(value)}
              <br />
              <Divider style={{ margin: '8px', borderColor: 'lightgray'}} />
              <br />
            </li>
          ))}
        </ul>
      );
    } else {
      return value;
    }
  };




  // Define a component to display the receipt data
  const ReceiptDetails = ({ receiptData }: { receiptData: any }) => {
    if (!receiptData) return null;
  
  // returns result in list format
    return (
        <div style={{ display: 'flex', flex: '1', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', overflowY: 'auto'}}>
         <div style={{flexDirection: "row"}}>
         <Message  colorTheme="success">Your document has been successfully processed!</Message>
         </div>

        {/* <View width={{ base: '320px', large: "440px" }} > */}
        {/* <Card style={{ width: "auto", marginTop: "20px", justifyContent: "center", alignItems: "center", overflowY: 'auto', overflowX: "scroll", backgroundColor: "black", color: "white",  boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)", borderRadius: "2px" }}> */}

        <Card style={{ height: "540px", width: "auto", marginTop: "20px", justifyContent: "center", alignItems: "center", overflowY: 'auto', overflowX: "scroll", backgroundColor: "white",  boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)", borderRadius: "2px" }}>
        {renderValue(receiptData)}
        </Card>
        {/* </View> */}
      </div>
    );
  };




// Myexpense functions
    // Function to delete a receipt from the database using GraphQL mutation
    const deleteRecord = async (id: string) => {
      try {
          await client.graphql
          ({
              query: deleteReceipt,
              variables: { input: { id } },
          });
          console.log('Receipt deleted successfully');
          // Call the function to list the receipts after deleting
          listRecords();
      } catch (error) {
          console.log('Error deleting receipt', error);
      }
  }
      
  


  const handleDelete = async (id: string) => {
      const confirmDelete = window.confirm('Are you sure you want to delete this receipt?');
      if (confirmDelete) {
          try {
              await deleteRecord(id);
              setReceiptsTable(receiptsTable.filter(receipt => receipt.id !== id));
              toast.success('Receipt deleted successfully');
          } catch (error) {
              console.error('Error deleting receipt:', error);
              toast.error('Failed to delete receipt');
          }
      } else {
          console.log('Deletion cancelled');
      }
  };



  // Function to list receipts from the database using GraphQL query for the current user
  const listRecords = useCallback(async () => {
      try {
          setListIsLoading(true);

      //setNextToken to update the receipts and next token
      const receiptListData: any = await client.graphql({
          query: listReceipts,
          variables: {
          filter: {
              // filter receipts by owner
              owner: {
                  eq: userEmail,
              },
              // filter receipts by createdAt last 3 months
              createdAt: {
                  beginsWith: new Date().toISOString().substring(0, 7), // filter by current month
                  // between: [new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(), new Date().toISOString()],
              },
          },
          },
      });
      console.log("receipt list:", receiptListData);
      // Sort the receipts by createdAt in descending order
      const sortedReceipts = receiptListData.data.listReceipts.items.sort((a: any, b: any) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      // Set the sorted receipts to the state
      setReceiptsTable(sortedReceipts);
      console.log("sorted receipts:", sortedReceipts);
      setListIsLoading(false);
  } catch (error) {
          console.log(error);
  }
  }, [userEmail]);

  
  // Call the function to list receipts if user is authenticated
  useEffect(() => {
      if (userEmail) {
          listRecords();
      }
      }, [userEmail, listRecords]);


  // Function to render the list as table
  const ReceiptListData = ({ receiptListData }: { receiptListData: any[]}) => {
      // Sort the receipts by createdAt in descending order
      const [sortField, setSortField] = useState('createdAt');
      const [sortOrder, setSortOrder] = useState('desc'); // 'asc' for ascending, 'desc' for descending


      const handleSort = (field: string) => {
          const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
          setSortField(field);
          setSortOrder(order);
      };


          const sortedReceipts = receiptListData.sort((a, b) => {
              let aValue = a[sortField];
              let bValue = b[sortField];


              // Handle sorting for different fields
              if (sortField === 'INVOICE_RECEIPT_DATE') {
                  const parseDate = (dateString: string) => {
                      const [day, month, year] = dateString.split('-').map(Number);
                      return new Date(year, month - 1, day); // Create a Date object with dayfirst format
                  };
                  aValue = aValue ? parseDate(aValue) : new Date(0); // Use epoch date for invalid dates
                  bValue = bValue ? parseDate(bValue) : new Date(0);
              } else if (sortField === 'TOTAL') {
                  aValue = parseFloat(aValue);
                  bValue = parseFloat(bValue);
              } else if (sortField === 'NAME') {
                  aValue = aValue.toLowerCase();
                  bValue = bValue.toLowerCase();
              }

              if (aValue < bValue) {
                  return sortOrder === 'asc' ? -1 : 1;
              }
              if (aValue > bValue) {
                  return sortOrder === 'asc' ? 1 : -1;
              }
              return 0;
          });
      
      // Calculate the sum of the total amount
      const totalAmount = sortedReceipts.reduce((sum, receipt) => sum + parseFloat(receipt.TOTAL || 0), 0);

      // Display sort arrows in headers
      const getSortArrow = (field: string) => {
          if (sortField === field) {
              return sortOrder === 'asc' ? '↑' : '↓';
          }
          return '↕'; // Default arrow indicating sortable column
      };


      return (
          <div>
              <View fontSize={{ base: '12px', medium: '16px', large: '20px' }} >
              <h2 style={{ textAlign: 'center', color: '#919098', fontSize: '20px' }}>My Expenses</h2>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul style={{ width: '90%', maxWidth: '800px', marginTop: '20px', alignContent: "center", listStyleType: 'none', padding: 0 }}>
                      <li style={{ color: '#3B3B40', border: '2px solid lightgray', backgroundColor: '#f2f2f2', padding: '8px', display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ width: '33%', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleSort('NAME')} title="Click to sort">
                              VENDOR {getSortArrow('NAME')}
                          </div>
                          <div style={{ width: '33%', textAlign: 'center', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleSort('INVOICE_RECEIPT_DATE')} title="Click to sort">
                              INVOICE/RECEIPT DATE {getSortArrow('INVOICE_RECEIPT_DATE')}
                          </div>
                          <div style={{ width: '33%', textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleSort('TOTAL')} title="Click to sort">
                              TOTAL AMOUNT {getSortArrow('TOTAL')}
                          </div>
                      </li>

                      {sortedReceipts.map((receipt) => (
                          <li key={uuidv4()} style={{ padding: '8px', display: 'flex', justifyContent: 'space-between', borderBottom: '2px solid lightgray' }}>
                              <div style={{ width: '33%' }}>{receipt.NAME}</div>
                              <div style={{ width: '33%', textAlign: 'center' }}>{receipt.INVOICE_RECEIPT_DATE || 'N/A'}</div>
                              <div style={{ width: '33%', textAlign: 'right' }}>{receipt.TOTAL}</div>
                              <button onClick={() => handleDelete(receipt.id)} className="myexpense-table-delete-button">x</button>
                          </li>
                      ))}

                      <li style={{ padding: '8px', display: 'flex', justifyContent: 'space-between', borderTop: '2px solid lightgray', backgroundColor: '#f2f2f2' }}>
                          <div style={{ width: '33%' }}></div>
                          <div style={{ width: '33%', textAlign: 'center', fontWeight: 'bold' }}>Total</div>
                          <div style={{ width: '33%', textAlign: 'right', fontWeight: 'bold' }}>{totalAmount.toFixed(2)}</div>
                      </li>

                  </ul>
              </div>
              </View>
          </div>
      );
      };
      






// const fontStyle = { fontFamily: "Sans-serif" };


  return (

    <div>
      {/* <Card style={{ display: 'flex', flex: '1', overflowY: 'auto'}}> */}
            <main>
            
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: '90px'}}>
              <Text style={{ fontSize: '14px', color: '#919098', paddingLeft: '20px' }}>Welcome! {userEmail}</Text>
            </div>


              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: "20px", paddingBottom: '20px', paddingTop: '20px'}}>
                <label className='upload-button' onClick={() => setIsUploadClicked(true)}>
                    Upload Receipt
                    <input type="file" onChange={handleFileChange} style={{ display: 'none'}} />
                </label>

                <span style={{ fontSize: '22px', color: '#fb3a46', padding: '0 20px', display: 'flex', alignItems: 'center' }}>/</span>

                <label className='add-expense-button' onClick={() => setIsManualEntryClicked(true)}>Add Expense</label>
              </div>


        {isUploadClicked && (
          <Card style={{ display: 'flex', flex: '1', justifyContent: "center", alignItems: "center", flexDirection: 'row', backgroundColor: '#f2f6f4', overflowY: 'auto' }}>
            <Flex direction={{ base: 'column', large: 'row' }}>
              {processedImageUrl && !isFetching && (
                <Card style={{ display: 'flex', flex: '1', justifyContent: "center", alignItems: "center", boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)", borderRadius: "2px", backgroundColor: 'white', margin: 'auto' }}>
                  {/* <div style={{ position: 'relative', width: 'auto', height: '600px' }}> */}
                    <img
                      src={processedImageUrl}
                      alt="Preview"
                      style={{ width: "auto", height: "600px" }}
                    />
                  {/* </div> */}
                </Card>
                    )}


              {isFetching && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh', flexDirection: 'column' }}>
                  <div className="loader" style={{ marginBottom: '20px'}}></div>
                  <Message  colorTheme="info">Your document is being processed...</Message>
                </div>
              )}

                {!isFetching && (
                  <ReceiptDetails receiptData={receipt} />
                )}

                {!isFetching && downloadUrl && (
                  <label className='download-button'>
                    <a href={downloadUrl} download className='download-link'>
                      Download CSV
                    </a>
                  </label>
                  )}
          </Flex>
        </Card>
      )}



            <div>
                {isManualEntryClicked && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={() => setIsManualEntryClicked(false)}>&times;</span>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Vendor:</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                {/* // Allow users to input date in the foramt Date/Month/Year only with / as the separator */}
                                <div className="form-group">
                                  <label>Invoice/Receipt Date:</label>
                                  <input
                                        type="date"
                                        value={invoiceDate}
                                        onChange={(e) => setInvoiceDate(e.target.value)}
                                    />
                                </div>

                                {/* // Allow users to input total in the foramat 0.00 only with . as the separator */}
                                <div className="form-group">
                                    <label>Amount:</label>
                                    <input
                                        type="number"
                                        value={total}
                                        onChange={(e) => setTotal(e.target.value)}
                                    />
                                </div>

                            </div>
                            <div className="form-actions">
                                <button className="save-button" onClick={createManualEntryRecord}>Save</button>
                                <button className="cancel-button" onClick={() => setIsManualEntryClicked(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
        </div>


    
        <ReceiptListData receiptListData={receiptsTable} />



      </main>
      </div>
      );
      }

       










// import { Divider, useAuthenticator } from "@aws-amplify/ui-react";
// import { v4 as uuidv4 } from 'uuid';
// import React, { MouseEventHandler } from 'react';
// import { Amplify } from 'aws-amplify';
// import awsExports from '../aws-exports';
// import awsconfig from '../aws-exports';
// import "@aws-amplify/ui-react/styles.css";
// import { getReceipt } from '../graphql/queries'; 
// import { createReceipt } from '../graphql/mutations';
// import { uploadData } from 'aws-amplify/storage';
// import { generateClient } from 'aws-amplify/api';
// import { useState, useRef } from 'react';
// import { listReceipts as listReceiptsQuery } from '../graphql/queries'; // Import the GraphQL query
// import { fetchUserAttributes, fetchAuthSession, signOut } from 'aws-amplify/auth';
// import { Text, Card, Button, Icon } from '@aws-amplify/ui-react';
// import { Message, Flex } from '@aws-amplify/ui-react';

// import { MarketingPricingHome, FeaturesHome, CTASectionHome, MarketingFooterHome } from "../ui-components";

// Amplify.configure(awsconfig);


// export function LiveScan() {
//   const { user } = useAuthenticator((context) => [context.user]);
  
//   const client = generateClient();

//   // Define state variables for the file upload count
//   const [uploadCount, setUploadCount] = useState(0);

//   // Define state variables for the upload status
//   const [uploadStatus, setUploadStatus] = useState<string | null>(null);

//   // Define state variables for the get receipt function
//   const [receipt, setReceipt] = useState(null);

//   interface Receipt {
//     id: String
//     owner: String
//     file: String
//     filename: String
//     NAME: String
//     RECEIPT_DATE: String
//     TOTAL: String
//   }


//   // Define state variables for the receipts and the next token
//   const [receipts, setReceipts] = useState<Receipt[]>([]);
//   const [nextToken, setNextToken] = useState(null);

//   const [imageUrl, setImageUrl] = useState<string | null>(null);
//   const [isFetching, setIsFetching] = useState(false);

//   // Define a state variable for the filter
//   const [filter, setFilter] = useState('');
//   const [isLoading, setIsLoading] = useState(false);

//   // Define a state variable for the filter type
//   const [filterType, setFilterType] = useState('VENDOR');

//   const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

//   const [filteredCsvUrl, setFilteredCsvUrl] = useState('');

//   const [userEmail, setUserEmail] = useState<string | null>(null);
//   const [isUploadClicked, setIsUploadClicked] = useState(false);

//     // Function to print access token and id token
//     async function handleFetchUserAttributes() {
//       try {
//         const userAttributes = await fetchUserAttributes();
//         setUserEmail(userAttributes.email ?? null);
//         console.log("user:", userAttributes);
//       } catch (error) {
//         console.log(error);
//       }
//     }
    
//     //call the function to print email and other user attributes
//     handleFetchUserAttributes();

    

//   // Define an async function to create the receipt
//   const createReceiptRecord = async (image: { filename: string, file: { bucket: string, region: string, key: string } }) => {
//     try 
//     {
//       const delay = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds));
//       // Get the current session
//       const session = await fetchAuthSession();
//       console.log("session:", session);
//       // const userGroup =  session.tokens?.idToken?.payload['cognito:groups'];
//       // console.log("userGroup:", userGroup);

//       // Create the API record:
//       const newReceipt:any = await client.graphql({
//         query: createReceipt,
//         // If createReceipt requires variables, pass them in like this:
//         variables: { input: image }, 
//         authToken: session.tokens?.idToken?.toString() ?? ''       
//       });

//       // Set the upload status
//       setUploadStatus('File uploaded!');

//       console.log("createReceiptRecord", newReceipt.data.createReceipt);
//       setIsFetching(true); // Start fetching
//       await delay(6000);
//       getReceiptRecord(newReceipt.data.createReceipt.id); 
//       setIsFetching(false); // End fetching
//     } catch (error) {
//       console.error("Error creating receipt", error);
//     }
//     }

  
//   // Define an async function to get the receipt
//   const getReceiptRecord = async (fileid: string) => {
//     try {
//       // Get the current session
//       const session = await fetchAuthSession();
//       // Get the API record:
//       const receiptData:any = await client.graphql({
//         query: getReceipt,
//         // If getReceipt requires variables, pass them in like this:
//         variables: { id: fileid },
//         authToken: session.tokens?.idToken?.toString() ?? ''       

//       });
//       // Set the receipt data in state
//       setReceipt(receiptData.data.getReceipt);
//       console.log("getReceiptRecord:", receiptData.data.getReceipt);

//       // call the function to convert the receipt data to csv
//       const csvData = objectToCsv(receiptData);

//       }
//     catch (error) {
//       console.error("Error getting receipt", error);
//     }
//     }
  


//   // Assuming `getFile` is a function that retrieves a File object from an input element or another source
//   const getFile = (e: React.ChangeEvent<HTMLInputElement>) => e.target.files ? e.target.files[0] : null;

//   // Define an event handler for the file input change event
//   const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

//     // Define a function to get the user session and group
//     const session = await fetchAuthSession();
//     const userEmail = session.tokens?.idToken?.payload['email'];
//     const userGroup: any =  session.tokens?.idToken?.payload['cognito:groups'];
//     console.log("userEmail:", userEmail);
//     console.log("userGroup:", userGroup);

//     // Query the number of uploads for the user using "owner" as the filter from the receipts table
//     const uploadCountData: any = await client.graphql({
//       query: listReceiptsQuery,
//       variables: {
//         filter: {
//           owner: {
//             eq: userEmail
//           }
//         },
//       },
//       authToken: session.tokens?.idToken?.toString() ?? ''       
//     });
//     console.log("uploadCountData:", uploadCountData);
//     const uploadCount = uploadCountData.data.listReceipts.items.length;
//     console.log("User's uploadCount:", uploadCount);


//     // If the userGroup list is undefined or does not have "paid_users", set the upload limit to 2 otherwise proceed with any number of uploads
//     if (!userGroup || !userGroup.includes("paid_users")) {
//       console.log(userEmail + " is not a paid user")
//       if (uploadCount >= 5) {
//         alert ("You have reached the maximum number of trials. Please upgrade to a paid plan to process more documents.");
//         return;
//       }
//     }

//     // Increment the upload count
//     setUploadCount(uploadCount + 1);




//     // Get the file
//     const file = getFile(e);

//     // Check if file is not null before uploading
//     if (file) {
//       setUploadStatus(null);
//       setReceipt(null);
//       setImageUrl(URL.createObjectURL(file));
//       // Create a unique file name:
//       const uuid = uuidv4();
//       const extension = (file.name).split('.').pop();
//       const fileNameWithoutExtension = (file.name).split('.').slice(0, -1).join('.');

//     // Sanitize the filename by replacing spaces and special characters with underscores
//     const sanitizedFileNameWithoutExtension = fileNameWithoutExtension.replace(/[^a-zA-Z0-9_.-]/g, '_');

//     const modifiedFileName = `${sanitizedFileNameWithoutExtension}_${uuid}.${extension}`;

//       // const modifiedFileName = `${fileNameWithoutExtension}_${uuid}.${extension}`;

//       // const modifiedFileName = uniquefilename(file.name);
//       // Upload the Storage file:
//       const result = await uploadData({ 
//         key: modifiedFileName,
//         // key: file.name, 
//         data: file,
//         options: {
//           contentType: 'image/*' // contentType is optional
//         }
        
//       }).result;
//       const image = {
//         filename: modifiedFileName,
//         file: {
//             bucket: awsExports.aws_user_files_s3_bucket,
//             region: awsExports.aws_user_files_s3_bucket_region,
//             key: modifiedFileName
//         }
//       }
//       createReceiptRecord(image);
//     }
//   }



//   // Create a CSV file from the receipt data
//   const objectToCsv = function (receiptData: any) {
//     // Create an empty array to store the csv data
//     const csvRows = [];

//     /* Get headers as every csv data format has header (head means column name) so objects key is nothing but column name 
//     for csv data using Object.key() function. We fetch key of object as column name for csv */
//     // const headers = Object.keys(receiptData.data.getReceipt)
//     const headers = ["VENDOR", "ADDRESS", "RECEIPT_DATE", "RECEIPT_TIME", "RECEIPT_NUMBER", "NUMBER_OF_ITEMS", "TOTAL_AMOUNT"];
    
//     // Create a mapping of original header names to new header names
//     const headerNameMapping = {
//       'VENDOR': 'VENDOR',
//       'ADDRESS': 'ADDRESS',
//       'RECEIPT DATE': 'RECEIPT_DATE',
//       'RECEIPT TIME': 'RECEIPT_TIME',
//       'RECEIPT NUMBER': 'RECEIPT_NUMBER',
//       'NUMBER OF ITEMS': 'NUMBER_OF_ITEMS',
//       'TOTAL AMOUNT $': 'TOTAL_AMOUNT'
//     };

//     // Map the original headers to the new headers
//     const newHeaders = headers.map(header => headerNameMapping[header as keyof typeof headerNameMapping] || header);

//     // Add the new headers to the CSV rows
//     csvRows.push(newHeaders.join(','));

//     // Loop through the headers and match the values with the headers
//     for (const header of headers) {
//       const values = headers.map(header => {
//         const value = receiptData.data.getReceipt[header];
//         if (value === null || value === undefined || value === '' || value.trim() === '') {
//           return '""';  // Return empty quotes for empty values
//         }
//         // Wrap the value in double quotes
//         return `"${value}"`;
//       });

//       // Push the values to the csvRows array
//       csvRows.push(values.join(','));

//     /* To add new line for each objects values and this return statement array csvRows to this function.*/
//       const csvData = csvRows.join('\n');
//       // console.log("csvData:", csvData);
    
//     // Generate csv file with the value of "filename" header in the receipt data as the file name
//     const csvfilename = receiptData.data.getReceipt.filename + '.csv';


//     //generate csv file with the csv data and filename
//     const generateCsv = (csvData: string, filename: string) => {
//       try {
//         const blob = new Blob([csvData], { type: 'text/csv' });
//         const url = URL.createObjectURL(blob);
//         setDownloadUrl(url); // Store the URL in the state
//       } catch (error) {
//         console.error('Error generating CSV:', error);
//       }
//       };

//     generateCsv(csvData, csvfilename);

//     return csvData;
//   }
//   }




// // Define a component to display the receipt data as list items
// const ReceiptDetails = ({ receiptData }: { receiptData: any }) => {
//   if (!receiptData) return null;

//   const fields = [
//     { label: 'VENDOR', value: receiptData.VENDOR },
//     { label: 'ADDRESS', value: receiptData.ADDRESS },
//     { label: 'RECEIPT DATE', value: receiptData.RECEIPT_DATE },
//     { label: 'RECEIPT TIME', value: receiptData.RECEIPT_TIME },
//     { label: 'RECEIPT NUMBER', value: receiptData.RECEIPT_NUMBER },
//     { label: 'NUMBER OF ITEMS', value: receiptData.NUMBER_OF_ITEMS },
//     { label: 'TOTAL AMOUNT $', value: receiptData.TOTAL_AMOUNT },
//   ];
  

//   const validFields = fields.filter(field => field.value != null);

//   //  check if validFields has only receiptData.filename, receiptData.createdAt and receiptData.owner and all other fields are null. If so, display a message
//   // if (validFields.length === 3 && validFields[0].label === 'filename' && validFields[1].label === 'UPLOADED ON' && validFields[2].label === 'UPLOADED BY') {
//   //   return (
//   //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
//   //       <Text style={{ fontSize: '30px', color: 'red' }}>Receipt is not clear, please scan again!</Text>
//   //     </div>
//   //   );
//   // }

// // Check if all fields are null or receiptData.TOTAL_AMOUNT is null or empty, if so display a message
//   // if (validFields.length === 0 || validFields[6].value === null || validFields[6].value === '') {
//   //   return (
//   //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
//   //       <Text style={{ fontSize: '30px', color: 'red' }}>Receipt is not clear, please scan again!</Text>
//   //     </div>
//   //   );
//   // }

//   // Check if all fields are null or empty, if so display a message
//   if (validFields.length === 0) {
//     return (
//       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
//         <Text style={{ fontSize: '30px', color: 'red' }}>Receipt is not clear, please scan again!</Text>
//       </div>
//     );
//   }



//   return (
//     <ul style={{ listStyle: 'none', padding: 0 }}>
//       {validFields.map(field => (
//         <li key={field.label} style={{ display: 'flex', flex: '1', justifyContent: 'space-between', gap: '200px', alignItems: 'center', padding: '10px', borderBottom: '1px solid #c4c4c4' }}>
//           <Text style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>{field.label}</Text>
//           <Text style={{ fontSize: '13px', color: '#333', display: 'flex', flex: '1', justifyContent: 'flex-end' }}>{field.value}</Text>
//         </li>
//       ))}
//       <Message style={{ marginTop: '30px' }} colorTheme="success">Your document has been successfully processed and saved!</Message>
//     </ul>
//   );
// }



// async function listReceipts() {

//   try {
//     setIsLoading(true);
//     let filterValue = filter;
//     // case-insesitive ans space-insensitive search for default VENDOR 
//     // let filterValue = filter.trim().toLowerCase()
//     if (filterType === 'RECEIPT_DATE') {
//       filterValue = filter;
//     }
//     // else if (filterType === 'owner') {
//     //   filterValue = filter;
//     // }
//     // Get the current session
//     const session = await await fetchAuthSession();
//     //setNextToken to update the receipts and next token
//     const receiptListData: any = await client.graphql({
//       query: listReceiptsQuery,
//       variables: {
//         filter: {
//           [filterType]: {
//             eq: filterValue
//           }
//         },
//       },
//       authToken: session.tokens?.idToken?.toString() ?? ''       
//     });
//     console.log(receiptListData);
//     setReceipts(receiptListData.data.listReceipts.items);
//     setIsLoading(false);
//     // console.log("filter value:", filterValue);

//     // call the function to convert the filtered receipt data to csv
//     const filteredCsvData = filteredObjectToCsv(receiptListData.data.listReceipts.items, filterType, filterValue);

//   } catch (error) {
//     console.error('Error fetching receipts:', error);
//   }
// }


//   // Define a function to convert the filtered receipt data to CSV
//   const filteredObjectToCsv = function (receiptListData: any[], filterType: string, filterValue: string) {
//         const csvRows = [];

//         const headers = ["VENDOR", "ADDRESS", "RECEIPT_DATE", "RECEIPT_TIME", "RECEIPT_NUMBER", "NUMBER_OF_ITEMS", "TOTAL_AMOUNT"];
        
//         // Create a mapping of original header names to new header names
//         const headerNameMapping = {
//           'VENDOR': 'VENDOR',
//           'ADDRESS': 'ADDRESS',
//           'RECEIPT DATE': 'RECEIPT_DATE',
//           'RECEIPT TIME': 'RECEIPT_TIME',
//           'RECEIPT NUMBER': 'RECEIPT_NUMBER',
//           'NUMBER OF ITEMS': 'NUMBER_OF_ITEMS',
//           'TOTAL AMOUNT $': 'TOTAL_AMOUNT'
//             };
        
//         // Map the original headers to the new headers
//         const newHeaders = headers.map(header => headerNameMapping[header as keyof typeof headerNameMapping] || header);

//         // Add the new headers to the CSV rows
//         csvRows.push(newHeaders.join(','));
        
        
//         // csvRows.push(headers.join(','));

//         for (const receiptData of receiptListData) {
//           const row = headers.map(header => {
//             const value = receiptData[header];
//             if (value === null || value === undefined || value === '' || value.trim() === '') {
//               return '""';
//             }
//             return `"${value}"`;
//           });

//           csvRows.push(row.join(','));
//         }


//         const filteredCsvData = csvRows.join('\n');
//         const filteredCsvfilename = `filtered_receipts_by_${filterType}_${filterValue}.csv`;
//         console.log("filename:", filteredCsvfilename);


//         const generateCsv = (csvData: string, filename: string) => {
//           try {
//             const blob = new Blob([csvData], { type: 'text/csv' });
//             const url = URL.createObjectURL(blob);
//             // Set the state with the URL
//             setFilteredCsvUrl(url);
//           } catch (error) {
//             console.error('Error generating CSV:', error);
//           }
//           };

//         generateCsv(filteredCsvData, filteredCsvfilename);

//         // return filteredCsvData;
//       };



//       // Define a component to display the list of filtered receipts as rows
//       const FilterReceiptDetails = ({ receiptListData }: { receiptListData: any }) => {
//         if (!receiptListData || receiptListData.length === 0) return null;
      
//         // Generate a list of all possible headers from the first receipt
//         const firstReceipt = receiptListData[0];
//         const allFields = [
//           { label: 'VENDOR', value: firstReceipt.VENDOR, id: `receiptVendor-${firstReceipt.id}`, name: 'receiptVendor' },
//           { label: 'ADDRESS', value: firstReceipt.ADDRESS, id: `receiptAddress-${firstReceipt.id}`, name: 'receiptAddress' },
//           { label: 'RECEIPT DATE', value: firstReceipt.RECEIPT_DATE, id: `receiptDate-${firstReceipt.id}`, name: 'receiptDate' },
//           { label: 'RECEIPT TIME', value: firstReceipt.RECEIPT_TIME, id: `receiptTime-${firstReceipt.id}`, name: 'receiptTime' },
//           { label: 'RECEIPT NUMBER', value: firstReceipt.RECEIPT_NUMBER, id: `receiptNumber-${firstReceipt.id}`, name: 'receiptNumber' },
//           { label: 'NUMBER OF ITEMS', value: firstReceipt.NUMBER_OF_ITEMS, id: `receiptItems-${firstReceipt.id}`, name: 'receiptItems' },
//           { label: 'TOTAL AMOUNT $', value: firstReceipt.TOTAL_AMOUNT, id: `receiptTotal-${firstReceipt.id}`, name: 'receiptTotal' },
//         ];
//         // const validHeaders = allFields.filter(field => field.value != null);
//         const validHeaders = allFields;

          
//         //  display the result of each receipt in the table by matching the headers with the values. Display only the valid fields
//         return (
//           <table style={{ 
//             maxWidth: '100%', 
//             overflowX: 'auto',
//             backgroundColor:'#d3d3d3', 
//             padding: '10px', 
//             borderRadius: '5px', 
//             boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)', 
//             margin: 0, 
//             borderCollapse: 'collapse'
//           }}>
//             <thead>
//               <tr>
//                 {validHeaders.map(field => (
//                   <th key={field.label} className="filter-result-table-header">
//                     {field.label}</th> // Add text-align here
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {receiptListData.map((receipt: any) => {
//                 const fields = [
//                   { label: 'VENDOR', value: receipt.VENDOR, id: `receiptVendor-${receipt.id}`, name: 'receiptVendor' },
//                   { label: 'ADDRESS', value: receipt.ADDRESS, id: `receiptAddress-${receipt.id}`, name: 'receiptAddress' },
//                   { label: 'RECEIPT DATE', value: receipt.RECEIPT_DATE, id: `receiptDate-${receipt.id}`, name: 'receiptDate' },
//                   { label: 'RECEIPT TIME', value: receipt.RECEIPT_TIME, id: `receiptTime-${receipt.id}`, name: 'receiptTime' },
//                   { label: 'RECEIPT NUMBER', value: receipt.RECEIPT_NUMBER, id: `receiptNumber-${receipt.id}`, name: 'receiptNumber' },
//                   { label: 'NUMBER OF ITEMS', value: receipt.NUMBER_OF_ITEMS, id: `receiptItems-${receipt.id}`, name: 'receiptItems' },
//                   { label: 'TOTAL AMOUNT $', value: receipt.TOTAL_AMOUNT, id: `receiptTotal-${receipt.id}`, name: 'receiptTotal' },
//                 ];
//                 // const validFields = fields.filter(field => field.value != null);
//                 const validFields = fields;


//                 return (
//                   <tr key={receipt.id}>
//                     {validFields.map(field => (
//                       <td key={field.label} className="filter-result-table-data">
//                         {field.value || ''} {/* Display an empty string if the value is null, To align headers with values for "owner" filter results */}
//                       </td> 
//                     ))}
//                   </tr>
//                 );
//               }
//               )}
//             </tbody>
//           </table>
          
//         );
//       }
      

//   const fontStyle = { fontFamily: "Sans-serif" };


//   return (
//     <div>
//       {/* <Card style={{ display: 'flex', flex: '1', overflowY: 'auto'}}> */}
//             <main>
            
//             <div style={{ display: 'flex', flex: '1', flexDirection: 'row', paddingLeft:'20px', justifyContent: 'flex-start', paddingTop: '120px'}}>
//               <Text style={{ fontSize: '14px', color: '#919098' }}>Welcome! You're signed-in as {userEmail}</Text>
//             </div>


//               <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', paddingBottom: '40px', paddingTop: '20px'}}>
//                 <label className='upload-button' onClick={() => setIsUploadClicked(true)}>
//                     Upload File
//                     <input type="file" onChange={handleFileChange} style={{ display: 'none'}} />
//                 </label>
//               </div>

//             {isUploadClicked && (
//             <Card style={{ display: 'flex',  flex: '1', justifyContent: 'space-between', flexDirection: 'row', padding: '40px', backgroundColor: '#f2f6f4', marginTop: '20px', overflowY: 'auto'}}>
//             <div style={{ display: 'flex',  flex: '1', alignItems:'center', flexDirection: 'column', flexWrap: 'wrap'}}>
//               {imageUrl ? (
//                 <img src={imageUrl} alt="Preview" style={{ width: 'auto', height: '450px' }} />
//                 ) : null}
//               </div>

//               <div style={{ display: 'flex',  flex: '1', alignItems: 'flex-start', flexDirection: 'column', flexWrap: 'wrap'}}>
//                 {isFetching ? (
//                   // <p>Scanning Receipt...</p>
//                   <div className="loader"></div>
//                 ) : (
//                 <ReceiptDetails receiptData={receipt}/>
//                 )}
//               </div>

//             <div style={{  display: 'flex', flex: '1', justifyContent: 'flex-end' }}>
//             {receipt && downloadUrl && (
//             <label className='download-button'>
//             <a href={downloadUrl} download className='download-link'>
//               Download CSV
//             </a>
//             </label>
//              )}
//             </div>

//           </Card>
//           )}


//           <div style={{ display: 'flex', flex: '1',  alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start', gap: '10px'}}>
//             <div style={{ display: 'flex', flex: '1',  alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start', gap: '10px', marginLeft: '20px'}}>
//             <Icon
//               pathData="M142.938822,125.786164 L133.905089,125.786164 L130.703259,122.698685 C142.296993,109.25125 148.66898,92.0834126 148.656375,74.3281875 C148.656375,33.2778631 115.378512,0 74.3281875,0 C33.2778631,0 0,33.2778631 0,74.3281875 C0,115.378512 33.2778631,148.656375 74.3281875,148.656375 C92.7387078,148.656375 109.662664,141.909663 122.698685,130.703259 L125.786164,133.905089 L125.786164,142.938822 L182.961692,200 L200,182.961692 L142.938822,125.786164 Z M73.5042735,124.786325 C45.1282051,124.786325 22.2222222,101.880342 22.2222222,73.5042735 C22.2222222,45.1282051 45.1282051,22.2222222 73.5042735,22.2222222 C101.880342,22.2222222 124.786325,45.1282051 124.786325,73.5042735 C124.786325,101.880342 101.880342,124.786325 73.5042735,124.786325 Z"
//               viewBox={{
//                 width: 150,
//                 height: 150,
//               }}
//               ariaLabel="Search"
//             />
//             <h1 style={{color: '#3B3B40', fontSize:'20px'}}>Filter Documents</h1>
//             </div>
//             </div>

//             <Card style={{  backgroundColor: '#f2f6f4',  overflowY: 'auto', marginBottom: '4px' , marginTop: '4px'}} >
//             <div  style={{ display: 'flex', flex: '1',  alignItems: 'center', flexDirection: 'row', justifyContent: 'center', gap: '10px', marginTop: '20px'}}>
//               <select value={filterType} onChange={e => setFilterType(e.target.value)} style={{ padding: '5px', color:'#525252', fontSize: '15px', width: '120px', height: '50px',  borderRadius: '5px', border: '1px Gray' }}>
//                   <option value="VENDOR">Vendor</option>                   
//                   <option value="RECEIPT_DATE">Receipt Date</option>
//                   {/* <option value="owner">Uploaded By</option> */}

//               </select>
//                 <input type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder={`Enter ${filterType}`} style={{ padding:'5px', color:'#525252', fontSize: '15px', width: '120px', height: '50px', borderRadius: '5px', border: '1px Gray'}} />
//                 <label className="filter-button" onClick={listReceipts} >
//                 Filter scanned documents
//                 </label>
//               </div>


//               <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px', marginBottom: '20px'  }}>
//               {filteredCsvUrl && (
//                <button className="download-for-filter-button" style={{ border: 'none', marginBottom: '10px', marginRight: '20px'}}  onClick={() => {
//                  const link = document.createElement('a');                 
//                  const filename = `Receipts_filtered_by_${filterType}_${filter}.csv`;
//                  link.href = filteredCsvUrl;
//                  link.download = filename as string; // cast filteredCsvUrl to string
//                  document.body.appendChild(link);
//                  link.click();
//                  document.body.removeChild(link);
//                }}>

//                  Download CSV of Filtered Documents
//                </button>
//                )}
//               </div>


//               <div style={{ display: 'flex', flex: '1',  alignItems: 'center', justifyContent: 'center', marginBottom: '40px'}}>
//                 <FilterReceiptDetails receiptListData={receipts} />
//               </div>
//             </Card>

//       </main>
//       {/* </Card> */}
//   </div>

//   );
// }
// // Path: src/pages/Profile.js


