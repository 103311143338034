import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react'; // Import the UseAuthenticator hook to find the current authentication state and display the routes accordingly.
import { signOut } from 'aws-amplify/auth';
import { Card , Image } from "@aws-amplify/ui-react";
import iravadlogo from "../FullColorIravadLogoOnly.jpg";
import '../App.css';
import { useState } from 'react';


export function Layout() {

    const { route, signOut } = useAuthenticator(context => [
        context.route,
        context.signOut,
    ]);
    const navigate = useNavigate();

    // const [showMenu, setShowMenu] = useState(false); //state of showMenu is false
    // const toggleMenu = () => {
    //     setShowMenu(!showMenu); 
    // };

    function handleSignOut() {
        signOut();
        navigate('/login');
    }



    
    return (
        <>
        <nav className="navbar">
        <Card style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: '100%', backgroundColor: 'white', boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.2)' }}>
        
        <div className="navbar-container1" >
        <Image src={iravadlogo} alt="IravadLogo" className="navbar-logo" onClick={() => navigate('/')} /> 
        <button className="nav-buttons" onClick ={() => navigate('/')}>Home</button>
        {/* <button className="nav-buttons" onClick ={() => navigate('/MyExpense')}>My Expenses</button>  */}
        {/* <button className="nav-buttons" onClick ={() => navigate('/OcrApi')}>OCR API</button> */}
        </div>


        <div className="navbar-container2">
        {route !== "authenticated" ? (
        <>
            <button className="nav-buttons" onClick={() => navigate('/MyExpense')}>Login</button>
            <button className="nav-freetrial-button" onClick={() => navigate('/MyExpense')}>Free Trial</button>
        </>
        ) : (
        <>
            <button className="nav-myexpense-button" onClick={() => navigate('/MyExpense')}>My Expenses</button>
            <button className="nav-freetrial-button" onClick={() => handleSignOut()}>Sign out</button>
        </>
        )}
        </div>

        </Card>
        </nav>
        <Outlet />
        </>
    );
    };


//     return (
//         <>
//             <nav className="navbar">
//                 <Card style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", width: '100%', backgroundColor: 'white', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)' }}>
//                         <Image src={iravadlogo} alt="IravadLogo" className="navbar-logo" onClick={() => navigate('/')} />
                            
//                         {/* The .nav-menu class hides the menu by default. */}
//                         {/* The .nav-menu.active class displays the menu when it is active. */}
//                         {/* <div className={showMenu ? "nav-menu active" : "nav-menu"}> */}
//                         <div className="navbar-container1" >
//                         <button className="nav-buttons"  onClick ={() => navigate('/')}>Home</button>
//                         <button className="nav-buttons" onClick ={() => navigate('/LiveScan')}>Live Scan</button> 
//                         {/* <button className="nav-buttons" onClick ={() => navigate('/OcrApi')}>OCR API</button> */}
//                         </div>


//                         <div className="navbar-container2">
//                         {route !== "authenticated" ? (
//                         <>
//                             <button className="nav-buttons" onClick={() => navigate('/LiveScan')}>Login</button>
//                             <button className="nav-freetrial-button" onClick={() => navigate('/LiveScan')}>Free Trial</button>
//                         </>
//                         ) : (
//                         <>
//                             <button className="nav-myexpense-button" onClick={() => navigate('/MyExpenses')}>My Expenses</button>
//                             <button className="nav-freetrial-button" onClick={() => handleSignOut()}>Sign out</button>
//                         </>
//                         )}
//                         </div>
//                         {/* </div> */}



//                     {/* <div className={`hamburger ${showMenu ? "open" : ""}`} onClick={toggleMenu}>
//                         <div className="bar"></div>
//                         <div className="bar"></div>
//                         <div className="bar"></div>
//                     </div> */}
//                 </Card>
//             </nav>
//             <Outlet />
//         </>
//     );
// }





// showMenu: This is a state variable that holds a boolean value (true or false). It indicates whether the menu should be shown or hidden.
// toggleMenu: This is a function that changes the value of showMenu. It toggles the state between true and false.
// Explanation
// State Initialization:
// const [showMenu, setShowMenu] = useState(false); initializes showMenu to false.
// Toggle Function:
// const toggleMenu = () => { setShowMenu(!showMenu); }; toggles the value of showMenu between true and false.
// Conditional Rendering:
// <div className={showMenu ? "nav-menu active" : "nav-menu"}> applies the "active" class to the menu when showMenu is true.
// In summary, showMenu is the state that determines if the menu is shown, while toggleMenu is the function that changes this state.
// Conditional Class Application:

// <div className={showMenu ? "nav-menu active" : "nav-menu"}> applies the "active" class to the <div> element when showMenu is true.
// CSS Styling:

// The .nav-menu class hides the menu by default.
// The .nav-menu.active class displays the menu when it is active.
// The .hamburger class is hidden by default and displayed on screens smaller than 768px.